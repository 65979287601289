<template>
  <v-app>
    <v-dialog
        v-model="dialog" max-width="700">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            {{`${edit?'Update':"Add"} classroom`}}
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="resetForm">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <div class="row">
            <div class="col-6">
              <v-select
                  :items="academicYears"
                  v-model="academic_class.academic_year_id"
                  outlined
                  dense
                  item-text="year"
                  item-value="id"
                  label="Batch"
              >
              </v-select>
              <span class="text-danger" v-if="$v.academic_class.level_id.$error">This information is required</span>
            </div>
            <div class="col-6">
              <v-select
                  :items="levels"
                  v-model="academic_class.level_id"
                  outlined
                  dense
                  item-text="title"
                  item-value="id"
                  label="Level"
                  @change="getPrograms"
              >

              </v-select>
              <span class="text-danger" v-if="$v.academic_class.level_id.$error">This information is required</span>
            </div>
            <div class="col-6">
              <v-select
                  :items="programs"
                  v-model="academic_class.program_id"
                  outlined
                  dense
                  label="Program"
                  item-text="title"
                  item-value="id"
                  @change="getGrades()"
              >

              </v-select>
              <span class="text-danger"
                    v-if="$v.academic_class.program_id.$error">This information is required</span>
            </div>
            <div class="col-6">
              <v-select
                  :items="gradesLevels"
                  v-model="academic_class.grade_id"
                  outlined
                  dense
                  label="Semester/Year"
                  item-text="title"
                  item-value="id"
              >

              </v-select>
              <span class="text-danger"
                    v-if="$v.academic_class.grade_id.$error">This information is required</span>
            </div>
            <div class="col-6">
              <v-text-field outlined dense v-model="academic_class.title"
                            placeholder="Class/section title"></v-text-field>
              <span class="text-danger" v-if="$v.academic_class.title.$error">This information is required</span>
            </div>
            <div class="col-6">
              <v-select :items="['morning', 'day', 'evening', 'night']" v-model="academic_class.shift"
                        outlined dense placeholder="Class shift"></v-select>
              <span class="text-danger" v-if="$v.academic_class.shift.$error">This information is required</span>
            </div>
            <div class="col-6">
              <v-select :items="rooms" item-text="title" item-value="id" v-model="academic_class.room_id"
                        outlined dense placeholder="Room" label="Room"></v-select>
            </div>
            <div class="col-6">
              <v-select :items="running_mode" item-value="value" item-text="title" v-model="academic_class.running_mode"
                        outlined dense placeholder="Running mode " label="Running mode"></v-select>
            </div>
            <div class="col-6" v-if="academic_class.running_mode =='online'">
              <v-text-field outlined dense v-model="academic_class.online_class_url"
                            placeholder="Online class URL" label="Online class URL"> </v-text-field>

            </div>
            <div class="col-6" v-if="academic_class.running_mode =='online'">
              <v-select :items="online_class_type" item-value="value" item-text="title" v-model="academic_class.online_class_type"
                        outlined dense placeholder="Class type " label="Class type"></v-select>
            </div>
            <div class="col-6">
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="academic_class.start_date"
                      label="Class commencement date"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    :min="today"
                    v-model="academic_class.start_date"
                    @input="menu = false"
                    no-title
                    scrollable
                >
                </v-date-picker>
              </v-menu>
              <!--          <span class="text-danger" v-if="$v.academic_class.program_id.$error">Start Date is required</span>-->
            </div>
            <div class="col-6">
              <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="academic_class.end_date"
                      label="Class completion date"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    :min="academic_class.start_date"
                    v-model="academic_class.end_date"
                    no-title
                    scrollable
                    @input="menu2 = false"
                >
                </v-date-picker>
              </v-menu>
              <!--          <span class="text-danger" v-if="$v.exam.program_id.$error">End Date is required</span>-->
            </div>

            <!--        <pre>{{academic_class}}</pre>-->
            <div class="col-3">
              <v-switch
                  v-model="academic_class.is_active"
                  label="Status"
              >
              </v-switch>
            </div>

            <div class="col-12 text-right mt-4">
              <v-btn
                      class="btn btn-standard cancel-btn"
                      depressed
                      @click="resetForm"
              >Cancel
              </v-btn>
              <v-btn
                      class="text-white ml-2 btn btn-primary"
                      depressed
                      @click="createAndUpdate"
                      :loading="isBusy"
              >Save
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>

    </v-dialog>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";

const levelService = new LevelService()
const academicClassService = new AcademicClassService();
const programService = new ProgramService();
const academicYearService = new AcademicYearService();
const gradeLevelService = new GradeLevelService();
import RoomsService from "@/core/services/rooms/RoomsService";
const roomService = new RoomsService();

export default {
  name: "academic_class-create-and-update",
  validations: {
    academic_class: {
      level_id: {required},
      program_id: {required},
      title: {required},
      shift: {required},
      grade_id: {required}
    }
  },
  data() {
    return {
      dialog: false,
      menu: false,
      menu2: false,
      isBusy: false,
      edit: false,
      levels: [],
      programs: [],
      academicYears: [],
      rooms: [],
      today: new Date().toISOString().substr(0,10),
      running_mode: [
        {
          'title':'Online',
          'value':'online',
        },
        {
          'title':'Campus',
          'value':'campus',
        }
      ],
      online_class_type: [
        {
          'title':'Zoom',
          'value':'zoom',
        },
        {
          'title':'Microsoft Team',
          'value':'teams',
        },
        {
          'title':'Google Meet',
          'value':'meet',
        }
      ],
      gradesLevels: [],
      academic_class: {
        level_id: null,
        program_id: null,
        grade_id: null,
        shift: null,
        title: null,
        running_mode: "campus",
        start_date: null,
        end_date: null,
        is_active: true
      }
    };
  }, mounted() {
    this.getLevels();
    this.getAcademicYears()
    this.getRooms()
  },
  methods: {
    formatDatePickerWise(date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month}-${day}`
    },
    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;
      });
    },
    getPrograms() {
      programService.getAllByLevelId(this.academic_class.level_id).then(response => {
        this.programs = response.data;
      });
    },
    getAcademicYears() {
      academicYearService.all().then(response => {
        this.academicYears = response.data;


      });
    },
    getGrades() {
      gradeLevelService.getByProgram(this.academic_class.program_id).then(response => {
        this.gradesLevels = response.data;
      });
    },
    getRooms() {
      roomService
              .paginate()
              .then(response => {
                this.rooms = response.data.data;
              })
              .catch(error => {
                // console.log(error);
              });
    },
    showModal(academicClass = {}) {
      if (academicClass.id) {
        this.academic_class = academicClass
        this.academic_class.start_date = this.formatDatePickerWise(this.academic_class.start_date);
        this.academic_class.end_date = this.formatDatePickerWise(this.academic_class.end_date);
        this.edit = true
        this.getPrograms();
        this.getGrades();

      }
      else
      {
        this.edit = false;
        this.resetForm();
      }
      this.dialog = true;
    },
    hideModal() {
      this.dialog = false;
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.dialog = false;
    },
    createAndUpdate() {
      this.$v.$touch();
      if (this.$v.academic_class.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.updateAcademicClass();
        } else {
          this.createAcademicClass();
        }
      }
    },
    createAcademicClass() {
      this.isBusy = true;
      academicClassService.store(this.academic_class).then(response => {
        this.isBusy = false;
        this.resetForm()
        this.$emit("refresh");
        this.$snotify.success("Information added");
      });
    },
    updateAcademicClass() {
      this.isBusy = true;
      academicClassService.update(this.academic_class.id, this.academic_class).then(response => {
        this.isBusy = false;
        this.resetForm()
        this.$emit("refresh");
        this.$snotify.success("Information updated");

      });
    }, resetForm() {
      this.academic_class = {
        level_id: null,
        program_id: null,
        title: null,
        running_mode: "campus",
        start_date: null,
        end_date: null,
        is_active: true
      }
      this.edit = false
      this.$v.$reset()
      this.hideModal()

    }
  },
};
</script>
<style>
  .v-application--wrap {
    min-height: 0 !important;
  }
</style>
