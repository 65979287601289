<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Classrooms</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Classrooms
                </div>
              </div>
              <div
                class="breadcrumb-right"
                v-if="checkIsAccessible('academic-class', 'create')"
              >
                <div class="card-toolbar">
                  <v-btn
                    @click="openDialog()"
                    class="mt-4 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                    classroom
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="2">
                  <v-text-field
                    class="form-control"
                    outlined
                    clearable
                    v-model="search.name"
                    dense
                    v-on:keyup.enter="getAcademicClasses()"
                    label="Class name"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    class="form-control"
                    outlined
                    clearable
                    v-model="search.academic_year"
                    dense
                    v-on:keyup.enter="getAcademicClasses()"
                    :items="academicYears"
                    item-text="title"
                    item-value="id"
                    label="Academic year"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    class="form-control"
                    outlined
                    clearable
                    :items="programs"
                    v-model="search.program_id"
                    @change="getGrades"
                    dense
                    v-on:keyup.enter="getAcademicClasses()"
                    item-text="title"
                    item-value="id"
                    label="Program"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="1">
                  <v-select
                    class="form-control"
                    outlined
                    clearable
                    :items="gradesLevels"
                    v-model="search.grade_id"
                    dense
                    item-text="title"
                    item-value="id"
                    v-on:keyup.enter="getAcademicClasses()"
                    label="Semester/year"
                  >
                  </v-select>
                </v-col>
<!--                <v-col cols="12" md="3">-->
<!--                  <v-menu-->
<!--                    ref="menu"-->
<!--                    v-model="menu"-->
<!--                    :close-on-content-click="false"-->
<!--                    :return-value.sync="search.start_date"-->
<!--                    transition="scale-transition"-->
<!--                    offset-y-->
<!--                    min-width="auto"-->
<!--                  >-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
<!--                      <v-text-field-->
<!--                        v-model="search.start_date"-->
<!--                        label="Search by date"-->
<!--                        outlined-->
<!--                        dense-->
<!--                        persistent-hint-->
<!--                        prepend-inner-icon="mdi-calendar"-->
<!--                        prepend-icon=""-->
<!--                        readonly-->
<!--                        clearable-->
<!--                        v-bind="attrs"-->
<!--                        v-on:keyup.enter="getAcademicClasses()"-->
<!--                        v-on="on"-->
<!--                      ></v-text-field>-->
<!--                    </template>-->
<!--                    <v-date-picker-->
<!--                      v-model="search.start_date"-->
<!--                      no-title-->
<!--                      @input="menu2 = false"-->
<!--                      range-->
<!--                    >-->
<!--                      <v-spacer></v-spacer>-->
<!--                      <v-btn text color="primary" @click="menu2 = false"> Cancel </v-btn>-->
<!--                      <v-btn-->
<!--                        text-->
<!--                        color="primary"-->
<!--                        @click="$refs.menu.save(search.start_date)"-->
<!--                      >-->
<!--                        Ok-->
<!--                      </v-btn>-->
<!--                    </v-date-picker>-->
<!--                  </v-menu>-->
<!--                </v-col>-->
                <v-col cols="12" md="1">
                  <v-select
                    class="form-control"
                    outlined
                    clearable
                    :items="[
                      { text: 'Morning', value: 'morning' },
                      { text: 'Day', value: 'day' },
                    ]"
                    v-model="search.shift"
                    item-text="text"
                    item-value="value"
                    dense
                    v-on:keyup.enter="getAcademicClasses()"
                    label="Shift"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-select
                    class="form-control"
                    outlined
                    :items="statuses"
                    v-model="search.status"
                    dense
                    v-on:keyup.enter="getAcademicClasses()"
                    item-text="title"
                    item-value="value"
                    label="Status"
                    clearable
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="2" class="text-right">
                  <v-btn
                    @click.prevent="getAcademicClasses()"
                    class="btn btn-primary btn-block w-35 float-right"
                    :loading="isBusy"
                    dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="isBusy"> </v-skeleton-loader>

              <v-skeleton-loader v-if="isBusy" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!isBusy">
                <thead>
                  <tr class="px-3 warp-column">
                    <th class="px-3 wrap-column">Class</th>
                    <th class="px-3 wrap-column">Academic info</th>
                    <th></th>
                  </tr>
                </thead>
                <template>
                  <tr v-for="(academicClass, index) of academic_classes" :key="index">
                    <td class="px-3 wrap-column">
                      <router-link
                        :to="{
                          name: 'academic-class-summary',
                          params: {
                            classId: academicClass.id,
                          },
                        }"
                      >
                      <simplebold>{{ academicClass.title }}</simplebold> </router-link>
                      <span class="badge-dot" v-bind:class="{ 'bg-color-green': academicClass.is_active, 'bg-color-red': !academicClass.is_active }"></span> <br/>
                       <simplebold>{{ academicClass.unique_no ? academicClass.unique_no : "" }} </simplebold> |
                      <simplebold>{{ academicClass.shift.toUpperCase() }}</simplebold> | <span class="count_number_badge">{{ academicClass.total_student }}</span> <br/>

                    </td>

                    <td class="px-3 wrap-column">
                      <simplebold>{{academicClass.program.short_title != "" ? academicClass.program.short_title : academicClass.program.title }}</simplebold> |
                      <simplebold>{{ academicClass.grade.title }}</simplebold> |
                      <simplebold>{{ academicClass.academic_year.title }}</simplebold> <br/>
                      <span>
                        <i class="fa-solid fa-square-check text-green mr-2"  v-if="academicClass.is_confirmed"></i>
                        <i class="fa-solid fa-rectangle-xmark  mr-2 text-red" v-else></i>
                        <i class="fa-solid fa-stopwatch text-green mr-2" v-if="!academicClass.is_complete"></i>
                        <i class="fa-solid fa-lock text-red  mr-2" v-else></i>
                      </span>
                    </td>
                    <td>
                      <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i> </span
                          ></slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <b-dropdown-text tag="div" class="navi-item">
                            <a
                              href="#"
                              class="navi-link"
                              @click="editClassroom(academicClass.id)"
                            >
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Edit </span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text
                            tag="div"
                            class="navi-item"
                            v-if="checkIsAccessible('academic-class', 'delete')"
                          >
                            <a
                              href="#"
                              class="navi-link"
                              @click="__delete(academicClass)"
                            >
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span class="navi-text"> Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </td>
                  </tr>
                  <tr v-if="academic_classes.length == 0">
                    <td class="px-3 text-center" colspan="4">
                      <strong>No data available to display.</strong>
                    </td>
                  </tr>
                </template>
              </table>
              <b-pagination
                @input="getAcademicClasses"
                v-model="page"
                v-if="academic_classes.length > 0"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                class="pull-right mt-7"
                :disabled="isBusy"
              ></b-pagination>
            </div>
          </div>

          <v-dialog v-model="delete_class" max-width="600">
            <v-card>
              <v-card-title> Delete {{ academic_class.title }} </v-card-title>
              <v-card-text>
                <v-text-field outlined dense label="Type confirm" v-model="confirmText">
                </v-text-field>
              </v-card-text>
              <v-card-action>
                <v-container>
                  <v-row>
                    <v-col cols="12" class="text-right">
                      <v-btn
                        @click="__confirmDelete()"
                        :disabled="confirmText != 'confirm'"
                        class="btn btn-primary text-white"
                      >
                        Delete
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-action>
            </v-card>
          </v-dialog>
          <assign-courses
            ref="assignCourse"
            @refresh="getAcademicClasses"
          ></assign-courses>
          <assign-students
            ref="assignStudents"
            @refresh="getAcademicClasses"
          ></assign-students>
          <assign-teacher
            ref="assignTeacher"
            @refresh="getAcademicClasses"
          ></assign-teacher>
          <create-and-update
            ref="academic-class"
            @refresh="getAcademicClasses"
          ></create-and-update>
          <exam-create-and-update
            ref="exam"
            @refresh="pushToExam"
          ></exam-create-and-update>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import ExamCreateAndUpdate from "../exams/CreateAndUpdate";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import AssignCourses from "./AssignCourses";
import AssignStudents from "./AssignStudents";
import AssignTeacher from "./AssignTeacher";
import CreateAndUpdate from "./CreateAndUpdate";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";

const academicClassService = new AcademicClassService();
const programService = new ProgramService();
const academicYearService = new AcademicYearService();
const gradeLevelService = new GradeLevelService();

export default {
  name: "academic-class",
  components: {
    CreateAndUpdate,
    AssignCourses,
    AssignStudents,
    AssignTeacher,
    ExamCreateAndUpdate,
  },
  data() {
    return {
      search: {
        academic_year: "",
        program_id: "",
        grade_id: "",
        type: "",
        status: "running",
        shift: "",
        name: "",
        start_date: [],
      },
      confirmText: "",
      academic_class: {},
      academicYears: [],
      academic_classes: [],
      programs: [],
      gradesLevels: [],
      statuses: [
        { title: "Running", value: "running" },
        { title: "Completed", value: "completed" },
      ],
      closeOnClick: true,
      isBusy: false,
      menu: false,
      menu2: false,
      delete_class: false,

      academic_id: null,
      page: null,
      total: null,
      perPage: null,
    };
  },
  mounted() {
    this.getAcademicYears();
    this.getPrograms();
    this.getAcademicClasses();
  },
  methods: {
    getAcademicClasses() {
      this.isBusy = true;
      /* this.$bus.emit('toggleLoader');*/
      academicClassService
        .paginate(this.search, this.page)
        .then((response) => {
          this.academic_classes = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch((err) => {
          // console.log(err)
        })
        .finally(() => {
          /* this.$bus.emit('toggleLoader');*/
          this.isBusy = false;
        });
    },
    getGrades() {
      gradeLevelService.getByProgram(this.search.program_id).then((response) => {
        this.gradesLevels = response.data;
      });
    },
    getPrograms() {
      programService.all().then((response) => {
        this.programs = response.data;
      });
    },
    getAcademicYears() {
      academicYearService.paginate().then((response) => {
        this.academicYears = response.data.data;
      });
    },
    openDialog(academicClass) {
      this.$refs["academic-class"].showModal(academicClass);
    },
    assignCourse(academicClass) {
      this.$refs["assignCourse"].showModal(academicClass);
    },
    assignStudents(academicClass) {
      this.$refs["assignStudents"].showModal(academicClass);
    },
    assignTeacher(academicClass) {
      this.$refs["assignTeacher"].showModal(academicClass);
    },
    deleteAcademicYear(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            academicClassService.delete(id).then((response) => {
              this.$snotify.success("Classroom deleted");
              this.getAcademicClasses();
            });
          }
        },
      });
    },
    openExamDialog(academic_class) {
      this.$refs["exam"].showModal({}, academic_class);
    },
    pushToExam() {
      this.$router.push({ name: "exams" });
    },
    __delete(academic_class) {
      this.delete_class = true;
      this.academic_class = academic_class;
    },
    __confirmDelete() {
      this.delete_class = false;
      academicClassService.delete(this.academic_class.id).then((response) => {
        this.$snotify.success("Classroom deleted");
        this.getAcademicClasses();
      });
    },

    resetForrm() {
      this.search = {
        academic_year: "",
        program_id: "",
        grade_id: "",
        type: "",
        shift: "",
        name: "",
        start_date: [],
        status: "running",
      };
      this.getAcademicClasses();
    },
    editClassroom(id) {
      this.$router.push({
        name: "academic-class-summary",
        params: {
          classId: id,
        },
      });
    },
  },
};
</script>
